import { Injectable } from "@angular/core";
import { Progress } from "../shared/constants";

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    progress: Progress;
    zoomFactor = 1;
    ZOOM_KEY = "ZOOM_KEY";

    private type: OrientationType;

	get isLandscape(): boolean {
		return this.type === "landscape-primary";
	}

    constructor() {
        const zf = localStorage.getItem(this.ZOOM_KEY);
        if (zf) {
            this.zoomFactor = +zf;
        }
        this.type = screen.orientation.type;
		screen.orientation.addEventListener('change', () => {
			this.type = screen.orientation.type;
		});
    }
    
    async doFunc(func: () => Promise<any>) {
        await this.doPromise(new Promise(async (resolve, reject) => {
            try {
                await func();
                resolve(null);
            } catch (e) {
                reject(e);
            }
        }));
    }

    async doPromise(promise: Promise<any>, noSpinner?: boolean) {
        if (!noSpinner) {
            this.progress = Progress.InProgress;
        } else {
            this.progress = Progress.None;
        }
        let result: any;
        try {
            result = await promise;
            if (!noSpinner) {
                this.progress = Progress.Done;
                setTimeout(() => {
                    this.progress = Progress.None;
                }, 5000);
            }
        } catch (e) {
            this.progress = Progress.Error;
            throw e;
        }
        return result;
    }

    zoomIn() {
		this.zoomFactor += 0.1;
        localStorage.setItem(this.ZOOM_KEY, this.zoomFactor.toString());
	}

	zoomOut() {
		if (this.zoomFactor > 0.1) {
			this.zoomFactor -= 0.1
            localStorage.setItem(this.ZOOM_KEY, this.zoomFactor.toString());
		}
	}

    resetZoom() {
        this.zoomFactor = 1;
        localStorage.setItem(this.ZOOM_KEY, "1");
    }
}