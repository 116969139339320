import { Directory, Filesystem } from '@capacitor/filesystem';
import { Buffer } from 'buffer';

export const getUri = async(path) => {
    return await Filesystem.getUri({
        path,
        directory: Directory.External,
    });
}

export const writeBase64 = async (path: string, base64: string) => {
    await Filesystem.writeFile({
        path,
        directory: Directory.External,
        data: base64
    });
    
    return await getUri(path);
};

export const writeString = async (path: string, data: string) => {
    return await writeBase64(path, Buffer.from(data).toString('base64'));
};

export const readString = async (path: string) => {
    const base64 = await Filesystem.readFile({
        path,
        directory: Directory.External
    });

    return Buffer.from(base64.data, 'base64').toString();
}

export const deleteFile = async (path: string) => {
    await Filesystem.deleteFile({
        path,
        directory: Directory.External
    });
}